<template>
  <!-- 图表的容器，一定要有宽高 -->
  <div class="syj_awardprize syj_bg">
    <title class="commom-title"><span>投注号码排行</span></title>
    <div class="chartBox syj_page-width">
      <div class="syj_border-linear"></div>
      <div class="bgwhite" ref="another"></div>
    </div>
  </div>
</template>

<script>
// const echarts = require("echarts"); //折线图
//   import { heapMew } from "@api/screen"; //引入的接口文件
import {getActivity} from "../units/activity.js";
import maiyuanRequest from "../units/maiyaunRuquest.js";

export default {
  data() {
    return {
      nnbetting_number_sort_list: this.betting_number_sort_list,
      bettingNumberSortList: [],
      //award_statistics
    };
  },
  mounted() {
    this.bettingNumberSort();
  },
  methods: {
    bettingNumberSort() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "betting_number_sort",
        params: {activtiy_id: activtiyId, activity_round_id: activityRoundId},
      }).then((res) => {
        let response = res.data;
        if (response.status == 510000) {
          return
        }
        if (response.data) {
          // console.log("response.data.award_statistics",response.data.award_statistics)
          let awardstatisticsname = [];
          let awardstatisticsvalue = []
          
          response.data.name.forEach((item) => {
            
            awardstatisticsname.push(item)
          })
          response.data.value.forEach((item) => {
            awardstatisticsvalue.push(item)
          })
          that.bettingNumberSortList.name = awardstatisticsname
          that.bettingNumberSortList.value = awardstatisticsvalue
          this.stackedEcharts();
        }
      })
    },
    stackedEcharts() {
      //获取id并初始化图表
      const chart = this.$refs.another;
      // console.log('chart',chart)
      // console.log('chart111',this.$echarts)
      let that = this;
      const myChart = this.$echarts.init(chart);
      
      //配置项
      const option = {
        tooltip: {},
        legend: {},
        
        grid: {
          border: "none",
          with: "100%", left: "5%",
          right: "0%"
        },
        xAxis: {
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            textStyle: {
              color: "#000",
            },
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
          },
          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#e4e4eb",
              width: 1,
              type: "solid",
            },
          },
          nameTextStyle: {
            fontSize: 14,
            fontWeight: "lighter",
            color: "rgba(255, 255, 255, 1)",
          },
          offset: 6,
          nameLocation: "end",
          name: "(部门)",
          data: that.bettingNumberSortList.name,
        },
        yAxis: [
          {
            nameLocation: "end",
            name: "(注数)",
            nameTextStyle: {
              fontSize: 14,
              align: "right",
              verticalAlign: "bottom",
              fontWeight: "lighter",
              lineHeight: 20,
              color: "#000",
            },
            axisLine: {
              //x轴线的颜色以及宽度
              show: true,
              lineStyle: {
                color: "#e4e4eb",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,
              textStyle: {
                color: "#000",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 24,
            showBackground: true,
            backgroundStyle: {
              color: {
                image:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAECAYAAACUY/8YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4Nzg4NjI2Ny1jNjMzLWMxNDctOWI4NC1mZTk5YmMwZjUwZjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTlBOTRBMjI0OTA2MTFFRDkyNTZDMjMzQjQ5MjBBNzQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTlBOTRBMjE0OTA2MTFFRDkyNTZDMjMzQjQ5MjBBNzQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOmEwOGFhMWMxLTI4NDUtYjg0ZC1hN2YxLTNhNDI0OWFjNjkwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Nzg4NjI2Ny1jNjMzLWMxNDctOWI4NC1mZTk5YmMwZjUwZjEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5SE8bZAAAAH0lEQVR42mL84G2hyUBDwALEv2ltAU19wMRAYwAQYACw/ALMFUAFhQAAAABJRU5ErkJggg==",
                "repeat-y": "repeat",
                "repeat-x": "no-repeat",
              },
            },
            data: that.bettingNumberSortList.value,
            itemStyle: {
              color: {
                image:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAECAYAAABlaKSVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4Nzg4NjI2Ny1jNjMzLWMxNDctOWI4NC1mZTk5YmMwZjUwZjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUI4NzAwNkU0OTA0MTFFREJBQzQ5NDQ1Nzc0Q0RDMDciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUI4NzAwNkQ0OTA0MTFFREJBQzQ5NDQ1Nzc0Q0RDMDciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOmEwOGFhMWMxLTI4NDUtYjg0ZC1hN2YxLTNhNDI0OWFjNjkwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4Nzg4NjI2Ny1jNjMzLWMxNDctOWI4NC1mZTk5YmMwZjUwZjEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5g4HEnAAAALElEQVR42mL84G0Rw8DAoMNAfXCFBUj8B+L3NDD8P8jwJQw0AkwMNAQAAQYADLgGbAsx8OoAAAAASUVORK5CYII=",
                "repeat-y": "repeat",
              },
            },
            label: {
              show: true,
              
              lineHeight: 12,
              backgroundColor: null,
              position: "top",
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize(); //图表自适应的一个方法
      });
    },
  },
  props: ["betting_number_sort_list"],
};
</script>
<style scoped lang="less">
@import '~@/style/common.less';

.syj_awardprize {
  .syj_page-width {
    width: 1200px;
    .media(1200, {
      width: 1000px;
    });
    .media(1440, {
      width: 1000px;
    })
  }
  
}

.chartBox {
  width: 100%;
  margin-top: 35px;
  height: 300px;
  padding: 10px;
  
  .bgwhite {
    width: 1200px;
    height: 100%;
    background-color: aliceblue;
  }
  
  .media(1200, {
    .syj_page-width {
      width: 1000px;
    }
    .bgwhite {
      width: 1000px;
      height: 100%;
      background-color: aliceblue;
    }
  });
  .media(1440, {
    .syj_page-width {
      width: 1000px;
    }
    .bgwhite {
      width: 1000px;
      height: 100%;
      background-color: aliceblue;
    }
  });
}
</style>
