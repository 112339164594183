<template>
  <div class="jennyhome">
    <div class="bg-empty" v-if="ingstartnew == 0">
      <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/feihuodong.jpg"/>
    </div>
    <div v-else-if="ingstartnew == 1" class="syj_black-home">
      <div id="tophomeID" class="syj_black-content commonbanner"
           :style="{ backgroundImage: 'url(' + imgSrcbanner + ')' }">
        <!--  -->
        <div class="banner syj_page-width">
          <!-- logo 个人中心 -->
          <toplogoCustomer-info></toplogoCustomer-info>
          <div class="syj_page-btn">
            <button @click="onOpenbet">
              <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/lijitouzhu.png"/>
            </button>
          </div>
        </div>
        <!-- 投注 弹出层相关内容 start -->
        <div v-if="this.bets_lenght == 6">
          <Betpopsix-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo">
          </Betpopsix-info>
        </div>
        <!-- 投注 弹出层相关内容 start -->
        <div v-else>
          <Betpop-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo"></Betpop-info>
        </div>
      </div>
      
      <!-- 客户留言弹幕  start -->
      <message-info></message-info>
      <!-- 历届奖励 start -->
      <homehistoryWin-info :scrolllunbo="scrolllunbo" :goButtom="goButtom"></homehistoryWin-info>
      
      <guestrule-info v-if="onOpenguestule" @isGuessClose="isguessclose"></guestrule-info>
      <div class="mainPretty">
        <!--  投注进度 start-->
        <homeBetProgress-info @onOpenbet="onOpenbet" :onOpenBetinfo="onOpenBetinfo"></homeBetProgress-info>
        <!--  和奖金池-->
        <homePrizePool-info></homePrizePool-info>
      </div>
      
      <homecomparisonchart-info v-if="onishomechart" @ishomeChartclose="ishomechartclose"></homecomparisonchart-info>
      
      <div class="sideicon">
        <!-- 游戏规则 -->
        <div class="popiconguestule" @click="isopenguestule">
          <img src="../assets/images/guessruleicon.png"/>
        </div>
        <!-- 趋势对比图-->
        <div class="isonchart" @click="ishomechart">
          <img src="../assets/images/charticon.png"/>
        </div>
        <!-- 排行榜-->
        <div class="isonchart join" @click="onTouch()">
          <img src="../assets/images/homechart.png"/>
        </div>
        <!-- 排行榜-->
        <div class="tophome isonchart" @click="istop()">
          <img src="../assets/images/hometop.png"/>
        </div>
      </div>
      <homejoinpeople-info id="basicInformation"></homejoinpeople-info>
      
      <!-- 集中投注区间投注数-->
      <homecenterbet-info></homecenterbet-info>
      <!-- 集中投注区间投注数-->
      <hometongji-info></hometongji-info>
      <!-- 历届获奖者-->
      <homepastwin-info id="homepastwin"></homepastwin-info>
      <!-- 黑五标语-->
      <slogandepart-info></slogandepart-info>
      <!-- 最新参与投注-->
      <LatestBetting-info></LatestBetting-info>
    
    </div>
  </div>
</template>
<script>
import Betpop from "@/components/BetPop";
import Betpopsix from "@/components/BetPopsix";
import message from "@/components/message";
import homehistoryWin from "@/components/homeHistoryWin";
import homeBetProgress from "@/components/homeBetProgress";
import guestrule from "@/components/guestRule";
import homeprizepool from "@/components/homePrizePool";
import homecomparisonchart from "@/components/homeComparisonchart";
import hometongji from "@/components/hometongji";
import homejoinpeople from "@/components/homeJoinpeople";
import homecenterbet from "@/components/homeCenterbet";
import homepastwin from "@/components/homepastwIn";
import Toplogocustomer from "@/components/toplogoCustomer";
import LatestBetting from "@/components/latestBetting";
import homeSlogan from "@/components/homeslogan";
import maiyuanRequest from "../units/maiyaunRuquest.js";
import {currentActivity} from "../units/api.js";
import {setActivity} from "../units/activity.js";

export default {
  name: "blackhome",
  provide() {
    return {
      '$_parent': this
    }
  },
  data() {
    return {
      onOpenBetinfo: false,
      state: "",
      code: "",
      currentActivityInfo: null,
      onOpenguestule: false,
      onishomechart: false,
      imgSrcbanner: "",
      bets_lenght: null,
      ingstart: 1,
      ingstartnew: null,
      scrolllunbo: true,
    };
  },
  components: {
    "message-info": message,
    "homehistoryWin-info": homehistoryWin,
    "homeBetProgress-info": homeBetProgress,
    "homePrizePool-info": homeprizepool,
    "guestrule-info": guestrule,
    "homecomparisonchart-info": homecomparisonchart,
    "homejoinpeople-info": homejoinpeople,
    "homecenterbet-info": homecenterbet,
    "homepastwin-info": homepastwin,
    "Betpop-info": Betpop,
    "toplogoCustomer-info": Toplogocustomer,
    "Betpopsix-info": Betpopsix,
    "hometongji-info": hometongji,
    "LatestBetting-info": LatestBetting,
    "slogandepart-info": homeSlogan
  },
  created: async function () {
    await this.jennycurrent().then((res) => {
      this.currentActivityInfo = res.data.data;
      this.imgSrcbanner = this.currentActivityInfo.activity_round.banner;
      setActivity(
          JSON.stringify({
            activityId: res.data.data.activity.id,
            activityRoundId: res.data.data.activity_round.id,
          })
      );
      this.ingstartnew = res.data.data.ing;
    }).catch(() => {
      this.ingstartnew = 0;
    });
  },
  
  methods: {
    goButtom() {
      document.getElementById("homepastwin").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    onTouch() {
      document.getElementById("basicInformation").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    istop() {
      document.getElementById("tophomeID").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    jennycurrent() {
      return new Promise((resolve, reject) => {
        currentActivity()
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
      });
    },
    liveactive() {
      let that = this;
      //  console.log('aaaaaaaaaaaaaaaa')
      currentActivity().then((res) => {
        let response = res.data.data;
        let nowTime = new Date();
        let betting_end_time = new Date(
            response.activity_round.betting_end_time
        );
        let betting_start_time = new Date(
            response.activity_round.betting_start_time
        );
        // console.log(betting_end_time.getTime() , nowTime.getTime())//转换 时间戳比较用
        if (
            betting_end_time.getTime() < nowTime.getTime() ||
            nowTime.getTime() < betting_start_time.getTime()
        ) {
          that.$toast.show("投注已截止咯～", 10000);
          // console.log("jennnyyyyyyyy", res.data.ing);
          return;
        } else {
          this.onOpenBetinfo = true;
          that.bets_lenght = response.activity_round.bets_lenght;
        }
      });
    },
    logout(msg, status) {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "logout",
        data: {msg, status},
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        that.status = response.data.status;
        that.msg = response.data.msg;
      });
    },
    onOpenbet() {
      this.liveactive();
    },
    isopenguestule() {
      this.onOpenguestule = true;
    },
    isguessclose() {
      this.onOpenguestule = false;
    },
    ishomechart() {
      this.onishomechart = true;
    },
    ishomechartclose() {
      this.onishomechart = false;
    },
    IsClosebet() {
      this.onOpenBetinfo = false;
    },
  },
  mounted() {
    this.jennycurrent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import '~@/style/common.less';
// .commonbanner{background: url(../assets/images/banner_01.jpg);}
.bg-empty {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.syj_page-width {
  max-width: 1200px;
  position: relative;
  margin: auto;
  .media(1200, {
    width: 1000px;
  });
  .media(1440, {
    width: 1000px;
  })
}

.feihome {
  display: none;
}

.mainPretty {
  position: relative;
  
  &::after {
    background: url('https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20241108-084347.png') no-repeat right bottom /contain;
    position: absolute;
    content: "";
    width: 502px;
    height: 390px;
    right: 0;
    z-index: 1;
    bottom: 14px;
  }
}

.syj_black-home {
  background: url('https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homebg.jpg') repeat-y top center / contain #26003b;
  
  .sideicon {
    background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/f41af106fd3dd70a5465a2bdf911e4e4.png);
    //background-image: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/01fa7599cd193ae51c10d45be89e8bba.gif),url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/b5f5dc4b52bff6fbc79b180af211ac70.jpeg);
    width: 268px;
    height: 578px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  
  .commonbanner {
    &.syj_black-content {
      background-position: top center;
      background-size: cover;
    }
    
    height: 350px;
    
    width: 100%;
  }
  
  .marquee {
    margin-top: -290px;
  }
  
  .banner {
    height: 100%;
    position: relative;
    
    .syj_page-btn {
      position: absolute;
      bottom: 46px;
      left: 32%;
      z-index: 1;
      transform: translateX(-50%);
    }
  }
  
  width: 100%;
  position: relative;
  
  button {
    opacity: 0;
    font-weight: 600;
    letter-spacing: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 234px;
    height: 65px;
    background: transparent;
    border: none;
    border-radius: 25px;
    font-size: 16px;
  }
  
  .jennyhome /deep/ .el-loading-mask {
    background-color: rgba(255, 255, 255, 1) !important;
  }
}
</style>

<style scoped lang="less">
@import '~@/style/common.less';
.media(1200, {
  .sideicon {
    transform: scale(0.7);
    transform-origin: left bottom;
  }
  .mainPretty {
    &::after {
      content: unset;
    }
  }
});
.media(1440, {
  .sideicon {
    transform: scale(0.7);
    transform-origin: left bottom;
  }
  .mainPretty {
    &::after {
      content: unset;
    }
  }
});
</style>

