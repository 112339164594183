<template >
  <div class="syj_homeJoinpeople ">
    <div class="syj_page-width ">
      <div class="syj-commontitle">
        <title class="commom-title"><span>投注排行榜</span></title>
        <!-- <span class="refresh" @click="clearrefresh"><img src="../assets/images/joinrefreshicon.png" /> 刷新</span> -->
      </div>
      <ul>
        <li class="listfrist">
          <span v-for="(jionpeople, index) in jionpeopletitlelist" :key="index">
            <span>{{ jionpeople.titlename }}</span>
          </span>
        </li>
        <li v-for="(jionpeople, index) in jionpeoplelist" :key="index"> 
          <span class="ranking-list">{{jionpeople.sort}}</span>
          <span class="srcname"><img :src="jionpeople.avatar_url"> {{ jionpeople.name }}</span> 
           <span>{{ jionpeople.department }}</span>
          <span>{{ jionpeople.company }}</span>
          <span>{{ jionpeople.total }}</span><span>{{ jionpeople.amount_pay }}</span>
        </li>
        <li class="last">
        <button v-if="!issOpen"  @click="isOpen" class="joinbtn" >
          查看更多>>
        </button>
        <button v-else  class="joinbtn">
          我是有底线的...
        </button>
      </li>
      </ul>
    </div>
  </div>
</template>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import { getActivity } from "../units/activity.js";
export default {
  name: "homeJoinpeople",
  inject: ['reload'],
  data() {
    return { currentPage: 1, //当前页数 ，默认为1
         pageSize:"", // 每页显示数量  
       issOpen: false, // 是否展开全部信息的标识 Boolean 默认false
      jionpeopletitlelist: [
        { titlename: "姓名" },
        { titlename: "部门" },
        { titlename: "公司" },
        { titlename: "投注数量" },
        { titlename: "投注金额" },

      ],
      jionpeoplelist: [

      ],
    };
  },
  methods: {
    //   clearrefresh(){
    // 	this.homeJoinpeople() 
    // },  
    homeJoinpeople() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "/participants_new",
        params: { page: that.currentPage,activtiy_id: activtiyId, activity_round_id: activityRoundId },
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {

        let response = res.data;
        
        console.log("response.data", response.data)
          if(response.data.length > 0){
        response.data.forEach((e)=>{
            that.jionpeoplelist.push(e)
          })
        that.currentPage ++
        }else{
        this.issOpen=!this.issOpen 
        }
      });

    },  
    clearrefresh() {
      this.reload();
      console.log(this.reload);
    }, 
    isOpen(){ 
        this.homeJoinpeople()  
        // console.log(this.realtimeorderlist.length )
    }
  },
  mounted() {
    this.homeJoinpeople();
  }
};
</script>
<style lang="less">
.syj_homeJoinpeople ul  .srcname img{  
    z-index: 1;
}
.syj_homeJoinpeople ul li span.ranking-list{
  position: absolute;
  left: 100px;
  width: 50px;
}
.syj_homeJoinpeople ul li:nth-child(-n+4) span.ranking-list{
  color: #feeb83;
  font-weight: 900;
}
.syj_homeJoinpeople ul li  .srcname{position: relative;}
.syj_homeJoinpeople ul li:nth-child(2) .srcname:after ,.syj_homeJoinpeople ul li:nth-child(3) .srcname:after ,.syj_homeJoinpeople ul li:nth-child(4) .srcname:after{
    content: "";
    top: -5px;
    left: 30px;
    position: absolute;
    width: 43px;
    height: 43px;
    background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20221123-152717.png) no-repeat;
    display: inline;
    z-index: 0;
}
.syj_homeJoinpeople ul li:nth-child(3) .srcname:after {
   background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20221123-152723.png) no-repeat;}
.syj_homeJoinpeople ul li:nth-child(4) .srcname:after {
   background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20221123-152618.png) no-repeat;}
.syj_homeJoinpeople { 
  .joinbtn{background: url("../assets/images/view-more.png") no-repeat;    width: 192px;
    height: 51px;
    align-items: normal;
    line-height: 45px;
    color: #fff;
    opacity: 1;
  }
  padding-bottom: 50px;

  .syj-commontitle {
    .refresh {
      cursor: pointer;
      position: absolute;
      right: 32px;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 64px;
      top: 77px;
    }

    display: flex;
    padding: 40px 27px;
    position: relative;
  }

  ul {
    li {
      font-weight: lighter;
      font-size: 18px;

      span {
        &.srcname {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 34px;
          width: 16%;
        }

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 10px;
        }

        width: calc(100% / 5);
      }

      &.listfrist:nth-child(2n+1) {
        span {
          display: inline;
        }

        color: #fff;
        background-image: linear-gradient(90deg, #dd0d1a, #dd0d1a, #dd0d1a);
        height: 44px;
      }

      height: 50px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 0 131px;

      &:nth-child(2n+1) {
        background: #363fb6;
      }
      &.last{
    text-align: center;
    justify-content: center;
    margin: 19px auto;
    background: transparent;}
    }

    display: flex;
    flex-direction: column;
  }
}
</style>