<template>
  <div class="slogandepart " id="roll">
    <div class="sloganswiper swiper-container ">
      <ul class="swiper-wrapper">
        <li class="swiper-slide" v-for="(slogan, index) in sloganlist" :key="index" :class="'slogan' + index">
          <span><img src="../assets/images/slogenicon.png"> {{ slogan }}</span>
        </li>
      </ul>
    </div>
  </div>

</template>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import "swiper/dist/css/swiper.min.css";
import Swiper from "swiper/dist/js/swiper.js";

export default {
  name: "slogandepart",
  data() {
    return {
      sloganlist: null,
    };
  },
  methods: {
    antitheticalcouplets() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "antithetical_couplets",
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        if (response.status == 510001) {
          return
        }
        that.sloganlist = response.data.left;
        // 调取金额滚动数字值
        setTimeout(() => {
          new Swiper(".sloganswiper", {
            slidesPerView: "1",
            spaceBetween: 40,
            loop: true, // 循环模式选项
            autoplay: true, //自动循环
          });
        }, 0)
      });
    },
  },
  mounted() {
    this.antitheticalcouplets();
  },
};
</script>
<style lang="less">
.slogandepart {
  z-index: 10;
  position: fixed;
  bottom: 348px;
  right: 15px;
  width: 495px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  
  ul {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    
    &:hover {
      animation-play-state: paused;
      cursor: pointer;
    }
  }
  
  li {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    
    span {
      max-width: 420px;
      display: inline-flex;
      background: rgba(192, 63, 72, 0.7);
      align-items: center;
      color: #fff;
      border-radius: 40px;
      padding: 0 20px 0 10px;
      min-height: 50px;
      text-align: left;
      line-height: 1.3;
      opacity: 1;
      
      img {
        z-index: 1;
        position: relative;
        margin-right: 4px;
      }
    }
  }
  
}
</style>

<style scoped lang="less">
@import '~@/style/common.less';
.media(1200, {
  .slogandepart {
    bottom: 220px;
    transform: scale(0.6);
    transform-origin: right bottom;
  }
});

.media(1440, {
  .slogandepart {
    bottom: 220px;
    transform: scale(0.6);
    transform-origin: right bottom;
  }
});

</style>
