<style lang="less">
.syj-home-win {
  padding-bottom: 40px;
  padding-top: 30px;
  
  .swiper-container.qwe {
    width: 94%;
    margin-top: 40px;
  }
  
  .swiper-button-prev,
  .swiper-button-next {
    background: transparent;
    top: 70%;
  }
  
  .swiper-button-prev {
    left: -3px
  }
  
  .swiper-button-next {
    right: -3px;
  }
  
  title {
    display: block;
  }
  
  .swiper-slide {
    .slide {
      width: 349px;
      height: 233px;
      overflow: hidden;
    }
    
    img {
      width: 100%;
    }
  }
}
</style>

<style lang="less" scoped>
@import '~@/style/common.less';

.syj_page-width {
  
  .media(1440, {
    .swiper-slide {
      .slide {
        width: 286px;
        height: 191px;
        overflow: hidden;
      }
    }
  });
}
</style>


<template>
  <div class="syj-home-win ">
    <div class="syj_page-width">
      <title class="commom-title"><span>历届锦鲤</span></title>
      <div class="swiper-container qwe">
        <div class="swiper-wrapper" v-if="imagelistswiper.length > 0">
          <div class="swiper-slide" v-for="data in imagelistswiper" :key="data.key">
            <div class="slide">
              <img :src="`${data.imgs}?x-oss-process=image/resize,w_500/quality,q_80/format,webp`" alt="图片未加载"/>
            </div>
          </div>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev prev">
        <img src="../assets/images/customer-lunboiconleft.png"/>
      </div>
      <div class="swiper-button-next next">
        <img src="../assets/images/customer-lunboicon.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import "swiper/dist/css/swiper.min.css";
import Swiper from "swiper/dist/js/swiper.js";

export default {
  name: "homepastwIn",
  data() {
    return {
      imagelistswiper: [],
    };
  },
  methods: {
    initSwiper() {
      new Swiper(".qwe", {
        slidesPerView: "3",
        spaceBetween: 40,
        loop: true, // 循环模式选项
        autoplay: true, //自动循环
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    homepastwin() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "previous_awards",
        data: {page: 1, limit: 1},
      }).then((res) => {
        let response = res.data;
        // let images = [];
        that.imagelistswiper = response.data;
        this.$nextTick(() => {
          this.initSwiper()
        })
        // console.log(" that.imagelistswiper.imgs",response.data)
      });
    },
  },
  mounted() {
    // 钩子函数
    this.homepastwin();
  },
};
</script>


