<template>
  <div class="marquee">
    <ul>
      <vue-danmaku
          ref="danmakuRef"
          v-model="barrageList"
          isSuspend
          useSlot
          :speeds="50"
          :channels="channels"
          :right="50"
          :top="topmargin"
          loop
          :style="style"
          randomChannel
      >
        <template slot="dm" slot-scope="{ danmu }"
                  :fontSize="fontsize">
          
          <div class="danmu-item baberrage-avatar">
            <img class="img" :src="danmu.avatar"/>
            <span>{{ danmu.msg }}</span>
          </div>
        </template>
      </vue-danmaku>
    </ul>
    
    <div class="message-comment-main" :class="{ active: isActive }">
      <div class="messagechartimg">
        <img @click="isMessageOpen" src="../assets/images/comment-user.png"/>
      </div>
      <div class="messageinput">
        <input
            @input="inputTextmessage($event)"
            placeholder="请输入弹幕"
            v-model="msg"
        />
        <div @click="addToList">发送</div>
      </div>
    </div>
    <div class="messagesuccess" v-if="isMessageComment">
      <div class="bgwhite">
        <div>
          <img
              @click="isMessageClose"
              src="../assets/images/messagesuccessclose.png"
              class="close"
          />
          <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/message-success.png" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import {sendBulletChat} from "../units/api.js";
import vueDanmaku from "vue-danmaku";

export default {
  name: "message",
  components: {
    "vue-danmaku": vueDanmaku,
  },
  data() {
    return {
      isActive: false,
      isMessageComment: false,
      msg: null,
      // barrageIsShow: true,
      currentId: 0,
      barrageLoop: true,
      barrageList: [],
      randomChannel: true,
      // maxWordCount: 1,
      style: "height: 300px; width: 100%",
      channels: 3,
      topmargin: 50,
    };
  },
  props: ["parentStyle"],
  methods: {
    homebarrageList() {
      maiyuanRequest({
        method: "get",
        url: "bullet_chat", //弹幕列表
      }).then((res) => {
        let response = res.data;
        if (response.data) {
          this.barrageList = response.data.map((t) => {
            return {
              avatar: t.avatar_url,
              // "https://s1-imfile.feishucdn.com/static-resource/v1/v2_87d3fcd1-9b71-4341-83b2-08237de9c83g~?image_size=noop&cut_type=&quality=&format=png&sticker_format=.webp",
              msg: t.title,
            }
          });
        }
      });
    },
    homebarrage() {
      sendBulletChat(this.msg).then((res) => {
        let response = res.data;
        if (response.data) {
          this.$refs.danmakuRef.insert({
            avatar: response.data.avatar_url,
            msg: response.data.title,
          })
          this.isMessageComment = true;
        }
      });
    },
    addToList() {
      this.msg = this.msg ? this.msg.trim() : null; //判断输入框是否空字符
      if (!this.msg) {
        this.$toast.show("请输入弹幕内容", 10000);
        return;
      }
      this.homebarrage();
    },
    isMessageOpen() {
      this.isActive = !this.isActive;
      this.msg = null;
    },
    isMessageClose() {
      this.isMessageComment = false;
      this.isActive = false;
      this.msg = null;
    },
    inputTextmessage(event) {
      this.msg = event.currentTarget.value;
    },
  },
  mounted() {
    if (this.parentStyle == "1") {
      this.style = "height: 208px; width: 100%";
      this.channels = 2;
      this.topmargin = 4;
    }
    console.log("加载");
    this.homebarrageList();
  },
};
</script>
<style lang="less">
.marquee {
  position: relative;
  
  .danmu-item {
    background: #fdf4ed;
    border-radius: 50px;
    color: #ff735c;
    display: inline-flex;
    padding-right: 10px;
    align-items: center;
    font-size: 15px;
    
    .img {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  
  .message-comment-main {
    pointer-events: auto;
    &.active {
      right: 16px;
      width: 452px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: url('https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/danmuactive.png');
      height: 65px;
      
      .messageinput {
        justify-content: space-between;
        width: 320px;
        display: flex;
      }
      
      .messagechartimg {
        background: transparent;
        width: 56px;
        margin-left: 30px;
      }
    }
    
    .messagechartimg {
      img {
        cursor: pointer;
        width: 32px;
      }
      
      background: url('https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/closecustomer.png');
      width: 80px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    width: 85px;
    position: absolute;
    right: 16px;
    bottom: 10%;
    z-index: 20;
    transition: all 0.3s;
    
    .messageinput {
      display: none;
      height: 29px;
      
      div {
        cursor: pointer;
        background: #ffcc00;
        font-size: 14px;
        color: #c0020e;
        line-height: 29px;
        border-radius: 13px;
        width: 65px;
        box-shadow: 1px 3px 4px #625e5e;
        font-weight: 600;
      }
    }
    
    input {
      &:focus-visible {
        outline: none;
      }
      
      width: 216px;
      border: none;
      border-radius: 50px;
      height: 29px;
      padding: 0 15px;
      color: #939393;
    }
  }
  
  .messagesuccess {
    position: absolute;
    width: 500px;
    z-index: 10;
    height: 220px;
    top: 50%;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    pointer-events: auto;
    
    .bgwhite {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        
        .close {
          position: absolute;
          right: 24px;
          cursor: pointer;
          top: 24px;
        }
      }
      
      width: calc(100% - 20px);
      margin: 10px;
      height: calc(100% - 20px);
    }
  }
  
  display: block;
  margin: 0px auto 0;
  
  .syj_popmessage {
    img {
      width: 26px;
    }
    
    background: #fff;
    position: absolute;
    min-width: 380px;
    z-index: 10;
    height: 80px;
    top: -32%;
    color: #000;
    right: 16px;
    display: flex;
    justify-content: space-around;
    flex-direction: inherit;
    align-items: center;
  }
  
  ul {
    margin: 7px 0 0 0;
    overflow: hidden;
    display: flex;
    color: #fff;
    flex-wrap: wrap;
    
    li {
      visibility: hidden;
      animation: barrage 10s linear 0s;
      
      img {
        width: 35px;
        height: 35px;
        background: #0b0c3d;
        border-radius: 50%;
      }
      
      margin: 0 15px 15px 0;
      height: 40px;
      background: #182d9d;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      border-radius: 25px;
    }
  }
}
</style>

<style lang="less" scoped>
.marquee {
  pointer-events: none;
  
  .vue-danmaku {
    pointer-events: none;
    ::v-deep .dm {
      pointer-events: auto;
    }
  }
  ul {
    margin: 0;
  }
}
.syj_realttime {
  .vue-danmaku {
    height: 250px !important;
    margin-top: 50px !important;
  }
}
</style>
