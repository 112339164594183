<template>
  <div class="syj_homeComparisonchart">
    <div class="syj_Betinfo-shadow"></div>
    <div class="fiexd">
      <div class="syj_page-width">
        <div class="syj_border-linear"></div>
        <div class="bgwhite">
          <img
              class="popclosegrule cursorPointer"
              @click="ishomechartclose"
              src="../assets/images/homePopclose.png"
          />
          <div class="icon-middle lefttop"></div>
          <div class="icon-middle leftbottom"></div>
          <title class="commom-title"><span>往期趋势对比</span></title>
          <div style="height: 250px;" ref="chart2">
            <div class="syj_isloading" v-show="isloading">努力加载中...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../units/request.js";
import {currentActivity} from "../units/api.js";

const echarts = require("echarts"); //折线图
export default {
  name: "homeComparisonchart",
  data() {
    return {
      charts: [],
      yearCharts: [],
      currentActivityInfo: null,
      currentYear: new Date().getFullYear().toString(),
      lastYear: (new Date().getFullYear() - 1).toString(),
      isloading: true,
    };
  },
  mounted() {
    // console.log("sssssssssssssssssssssssssss");
    this.getChartorder();
    // this.$nextTick(() => {
    // });
  },
  
  methods: {
    ishomechartclose() {
      this.$emit("ishomeChartclose");
    },
    initCharts2() {
      let that = this;
      let myChart = echarts.init(this.$refs.chart2);
      myChart.setOption({
        grid: {
          border: "none",
          with: "96%",
          left: "5%", right: '7%'
        },
        title: "",
        tooltip: {},
        xAxis: {
          nameLocation: "end",
          name: "(日期)",
          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#e4e4eb",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            interval: 0,
            show: true,
            textStyle: {
              color: "#000",
            },
          },
          splitLine: {
            //分割线配置
            show: false,
            color: "#c3c3d2",
            width: 1,
          },
          
          nameTextStyle: {
            // 名称样式
            fontSize: 12,
            color: "#000",
          },
          data: that.yearCharts.dateAt,
        },
        yAxis: {
          nameLocation: "end",
          name: "(订单数)",
          nameTextStyle: {
            align: "right",
            verticalAlign: "bottom",
            fontWeight: "lighter",
            lineHeight: 20,
            // 名称样式
            fontSize: 12,
            color: "#000",
          },
          splitNumber: 3,
          // min: 0,
          // max: 90000,
          axisLabel: {
            show: true,
            interval: 0,
            //y轴文字的配置
            textStyle: {
              color: "#000",
            },
          },
          axisLine: {
            //y轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#c3c3d2",
              width: 0,
              type: "solid",
            },
          },
        },
        //图例
        legend: {
          type: "plain",
          icon: "rect",
          x: "right", //图例位置
          y: "center", //图例位置
          padding: [0, 10, 0, 0],
          textStyle: {
            padding: [0, 0, 0, 5], // 修改文字和图标距离
            color: "#000",
          },
          align: "left",
          itemWidth: 15,
          itemHeight: 2,
          orient: "vertical",
          data: [this.currentYear, this.lastYear], //图例data必须与数据name一致
          right:"2%"
        },
        series: [
          //实线
          {
            name: this.currentYear,
            type: "line",
            // symbolSize: 3, //拐点圆的大小
            color: ["#ea354f"], //折线条的颜色
            data: that.charts.num,
            symbol: function (data, i) {
              console.log(i.name);
              // 根据时间判断是否使用图片
              if (i.name === '11/24') {
                return 'image://https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/a87937b9acb3a10f916c4c28a32291c8.png';
              } else if (i.name === '11/27') {
                return 'image://https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/6aefe72fa5e18da0cc73d1fffd50820e.png';
              } else {
                return 'circle'; // 默认使用普通圆点
              }
            },
            symbolSize: function (data, i) {
              // 根据时间返回不同的大小
              if (i.name === '11/24') {
                return 20;
              } else if (i.name === '11/27') {
                return 20;
              } else {
                return 4; // 默认大小
              }
            },
            smooth: false, //关键点，为true是不支持虚线的，实线就用true
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
          //实线
          {
            name: this.lastYear,
            type: "line",
            // symbol: "circle",
            // symbolSize: 4,
            itemStyle: {
              normal: {
                color: "#1d99da",
                borderColor: "#1d99da", //拐点边框颜色
              },
            },
            data: that.yearCharts.num,
            symbol: function (data, i) {
              console.log(i.name);
              // 根据时间判断是否使用图片
              if (i.name === '11/25') {
                return 'image://https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/a87937b9acb3a10f916c4c28a32291c8.png';
              } else if (i.name === '11/28') {
                return 'image://https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/6aefe72fa5e18da0cc73d1fffd50820e.png';
              } else {
                return 'circle'; // 默认使用普通圆点
              }
            },
            symbolSize: function (data, i) {
              // 根据时间返回不同的大小
              if (i.name === '11/25') {
                return 20;
              } else if (i.name === '11/28') {
                return 20;
              } else {
                return 4; // 默认大小
              }
            },
          },
        ],
      });
    },
    
    getChartorder() {
      let that = this;
      currentActivity().then((res) => {
        this.currentActivityInfo = res.data.data;
        console.log("当前活动", this.currentActivityInfo);
        if (this.currentActivityInfo.contrast_endtime) {
          this.currentYear = new Date(this.currentActivityInfo.contrast_endtime).getFullYear().toString()
          this.lastYear = (this.currentYear - 1).toString()
        }
        if (res.data.data.activity_round.exec_status == 0) {
          request({
            method: "post",
            url: "Blackfive/Orders",
            data: {
              type: "2",
              date: [
                res.data.data.contrast_starttime,
                res.data.data.contrast_endtime,
              ],
            },
            headers: {"Access-Token": "qHvOaOLnQekwIABVeBeZKupkNAZrRUzm"},
          }).then((res) => {
            this.isloading = false;
            let response = res.data;
            that.data = response.data;
            let dataThisYear = []; //今年数据
            let dataThisnum = []; //今年数据
            let dataLastYear = [];
            let dataLastnum = [];
            //今年的数据值
            that.data.this_year_data.forEach((yearItem) => {
              dataThisYear.push(yearItem.date_at); // .replace("2022/","")替换用的目前不需要了
              dataThisnum.push(yearItem.num);
            });
            that.charts.dateAt = dataThisYear;
            that.charts.num = dataThisnum;
            console.log("that.yearCharts.dateAt 今年", that.charts.num);
            //去年的数据值
            that.data.last_year_data.forEach((item) => {
              dataLastYear.push(item.date_at);
              dataLastnum.push(item.num);
            });
            
            that.yearCharts.dateAt = dataLastYear;
            that.yearCharts.num = dataLastnum;
            console.log("that.yearCharts.dateAt", that.yearCharts.num);
            
            this.initCharts2();
          });
        } else {
          let response = res.data;
          that.data = response.data.activity_round.big_car_orders.data;
          let dataThisYear = []; //今年数据
          let dataThisnum = []; //今年数据
          let dataLastYear = [];
          let dataLastnum = [];
          //今年的数据值
          that.data.this_year_data.forEach((yearItem) => {
            dataThisYear.push(yearItem.date_at); // .replace("2022/","")替换用的目前不需要了
            dataThisnum.push(yearItem.num);
          });
          that.charts.dateAt = dataThisYear;
          that.charts.num = dataThisnum;
          console.log("that.yearCharts.dateAt 今年", that.charts.num);
          //去年的数据值
          that.data.last_year_data.forEach((item) => {
            dataLastYear.push(item.date_at);
            dataLastnum.push(item.num);
          });
          
          that.yearCharts.dateAt = dataLastYear;
          that.yearCharts.num = dataLastnum;
          console.log("that.yearCharts.dateAt", that.yearCharts.num);
          
          this.initCharts2();
        }
      });
    },
  },
};
</script>
<style lang="less">
.syj_homeComparisonchart {
  .bgwhite {
    background: #fff;
    padding: 6px 0;
  }
  
  z-index: 100;
  
  .popclosegrule {
    float: right;
    padding: 0 10px;
  }
  
  .syj_page-width {
    padding: 10px;
    
  }
  
  .syj_isloading {
    font-size: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .fiexd {
    width: 100%;
    position: fixed;
    z-index: 20;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .syj_Betinfo-shadow {
    z-index: 20;
  }
}
</style>
<style scoped lang="less">
@import '~@/style/common.less';
.media(1200, {
  .syj_page-width {
    max-width: 80%;
  }
});

.media(1400, {
  .syj_page-width {
    max-width: 80%;
  }
});

</style>

