<template>
  <div class=" syj_latestbetting ">
    <div class="syjlatestbetting swiper-container">
      <div class="swiper-wrapper">
        <div v-for="item in homegetlatestbettinge" :key="item.key" class="swiper-slide">
          <span style="color: yellow; min-width: 70px;"> {{ item.name }}</span>:
          {{ item.total }}
          {{ item.amount_pay }} &nbsp;
          <span style="color: yellow;">{{ item.create_time }}</span>
        </div>
      </div>
    </div>
    <!--    <div class="swiper-pagination"></div>-->
  </div>
</template>
<script>
import {getActivity} from "../units/activity.js";
import maiyuanRequest from "../units/maiyaunRuquest.js";
import "swiper/dist/css/swiper.min.css";
import Swiper from "swiper/dist/js/swiper.js";

export default {
  name: "syj_latestbetting",
  data() {
    return {
      homegetlatestbettinge: [],
    };
  },
  methods: {
    getlatestbettinge() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "latest_betting",
        params: {activtiy_id: activtiyId, activity_round_id: activityRoundId},
      }).then((res) => {
        let response = res.data;
        that.homegetlatestbettinge = response.data;
        setTimeout(() => {
          new Swiper(".syjlatestbetting", {
            direction: "vertical",
            slidesPerView: 7,
            spaceBetween: 30,
            mousewheel: true,
            loop: true, // 循环模式选项
            autoplay: true, //自动循环
            slidesPerGroup: 7,
            pagination: {
              el: ".swiper-pagination",
              type: "progressbar",
            },
          });
        }, 0);
        console.log("homegetlatestbettinge", response.data)
      });
    },
  },
  mounted() {
    this.getlatestbettinge();
  },
};
</script>
<style lang="less">
.syj_latestbetting {
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  background: rgba(127, 10, 42, 0.25);;
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 20px;
  z-index: 10;
  padding: 15px 0px 15px 10px;
  width: 490px;
  height: 310px;
  overflow: auto;
  
  .swiper-pagination-progressbar { /*滚动条里面小方块*/
    background: transparent;
    height: 100px;
    width: 25px;
    top: 30px;
    left: 10px;
  }
  
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #9b4059;
    width: 16px;
    height: 100px;
    border-radius: 10px;
  }
  
  .syjlatestbetting {
    width: 90%;
    height: 100%;
  }
  
  .cursorPointer {
    margin-left: 10px;
  }
  
  .swiper-wrapper {
    margin: 16px 10px 16px 0;
    list-style: none;
    width: 100%;
    
    .swiper-slide {
      font-size: 20px;
      display: flex;
      text-align: left;
      justify-content: flex-start;
      display: flex;
      
      img {
        margin-right: 10px;
      }
    }
  }
}
</style>

<style scoped lang="less">
@import '~@/style/common.less';
.media(1200, {
  .syj_latestbetting {
    transform: scale(0.6);
    transform-origin: right bottom;
  }
});

.media(1440, {
  .syj_latestbetting {
    transform: scale(0.6);
    transform-origin: right bottom;
  }
});

</style>

