<template>
  <div class="syj_homeprizepool">
    
    <div class="syj_homeprizepool-content">
      <!-- 标语隐藏 -->
      <!-- <div class="slogan-depart">
        <div class="left common">
          <ul>
            <li v-for="(slogan, index) in sloganlist" :key="index" :class="'slogan' + index">
              {{ slogan }}
            </li>
          </ul>
        </div>
        <div class="right common">
          <ul>
            <li v-for="(slogan, index) in sloganlistright" :key="index" :class="'slogan' + index">
              {{ slogan }}
            </li>
          </ul>
        </div>
      </div> -->
      <div class="prizelist syj_page-width">
        <ul>
          <!-- <li class="refresh" @click="clear">
            <img src="../assets/images/home-prize-refresh.png" />
          </li> -->
          
          <li v-for="(prizelist, index) in prizelists" :key="index" :class="'prizeClass' + index">
            <template v-if="index != 3">
              <span class="first"><span class="money">￥</span>
                <span>
                  {{ prizelist.prizeNum }}
                </span>
              </span>
              <span>{{ prizelist.prizetitle }}</span>
            </template>
            <template v-else>
              <span class="first">
                <div v-for="(item, index) in listmoney" :key="index" class="numClass3 ">
                  <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
                    {{ indexs }}
                  </div>

                </div>
              </span>
            
            </template>
          
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import {getActivity} from "../units/activity.js";

export default {
  name: "homePrizePool",
  inject: ["reload"],
  data() {
    return {
      sloganlist: null,
      sloganlistright: null,
      prizelists: [],
      intervalId: null,  // 父级传入
      intervalIdcouplet: null,  // 父级传入
      quantity: 5, //默认9个
      delayed: true, //从零变化
      number: '', //数字
      time: 2000, //过度时间
      timing: 'ease', //过度动画速度
      num: 0,
      newValue: "",
    };
  },
  //金额滚动数字效果
  computed: {
    numArr() {
      if (this.num) {
        return (this.num + '').split('')
      } else {
        return new Array(this.number.length).fill(0)
      }
    },
    
    listmoney() {
      let arr = []
      let len = this.numArr.length
      if (this.quantity && this.quantity > len) {
        arr = [
          ...new Array(this.quantity - len).fill(0),
          ...this.numArr,
        ]
      } else {
        arr = this.numArr
      }
      return arr
    },
  },
  //金额滚动数字效果
  
  methods: {
    antitheticalcouplets() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "antithetical_couplets",
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        if (response.status == 510001) {
          return
        }
        that.sloganlist = response.data.left;
        that.sloganlistright = response.data.right;
        // 调取金额滚动数字值
      });
    },
    homePrizePool() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "amount_prize",
        params: {activtiy_id: activtiyId, activity_round_id: activityRoundId},
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        if (response.status == 510001 || response.status == 400) {
          return
        }
        that.prizelists = response.data;
        // console.log("jenny", response.data.amount_count);
        that.newValue = that.prizelists[3].prizeNum   // 调取金额滚动数字值
      });
    },
    // clear() {
    //   this.reload();
    //   console.log(this.reload);
    // },
    // 时时刷新数据
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null || this.intervalIdcouplet != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新奖金池总额" + new Date());
        this.homePrizePool(); //加载数据函数
      }, 3000);
      
      this.intervalIdcouplet = setInterval(() => {
        console.log("刷新奖金池总额" + new Date());
        this.antitheticalcouplets(); //加载数据函数
      }, 60000);
    },
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    clearduilian() {
      clearInterval(this.intervalIdcouplet); //清除计时器
      this.intervalIdcouplet = null; //设置为null
    },
    // 时时刷新数据 end
    // 滚动数字样式
    style(e) {
      return {
        transform: `translateY(-${e * 100}%)`,
        transition: this.time + 'ms',
        transitionTimingFunction: this.timing,
      }
    },
  },
  // 时时刷新数据
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.clearduilian()
  },
  mounted() {
    this.dataRefreh();
    this.homePrizePool();
    this.antitheticalcouplets();
    // 调取金额滚动数 start
    if (this.delayed) {
      setTimeout(() => {
        this.num = this.number
      }, 1000)
    } else {
      this.num = this.number
    }
    setTimeout(() => {
      this.number = this.newValue;
    }, 2000)
    // 调取金额滚动数 end
  },// 调取金额滚动数监听 start
  watch: {
    number: {
      handler(newValue, oldValue) {
        //newValue 新的值，oldValue变化前的值
        this.num = newValue
        console.log(newValue, oldValue)
      },
    },
  },// 调取金额滚动数监听 end
};
</script>
<style lang="less">
.record {
  display: flex;
  padding-top: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
}


.slogan-depart {
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 26px;
  color: hsla(0, 0%, 100%, .35);
  width: 100%;
  overflow: hidden;
  
  .common {
    height: 100%;
    width: 26%;
    font-weight: 600;
    
    ul {
      text-align: left;
      padding: 50px 0 50px 29px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      height: 75%;
      margin: 0;
      user-select: none;
      
      li {
        margin: 8px 0;
        
        transform: scale(1.1, 1.1);
        transform-origin: 0 0;
        
        &.slogan1 {
          padding-left: 20px;
        }
        
        &.slogan2 {
          padding-left: 15px;
        }
      }
    }
  }
}

@media (max-width: 1560px) {
  .slogan-depart {
    font-size: 18px;
    
    .common {
      width: 22%;
    }
  }
}

.numClass3 {
  font-size: 60px;
  color: #000;
  overflow: hidden;
}

.border {
  border: 1px solid #ccc;
}

.syj_homeprizepool {
  
  .syj_homeprizepool-content {
    position: relative;
    margin-top: 0;
    height: 338px;
    
    .syj_jianbian {
      z-index: 1;
      position: absolute;
      top: -43px;
      left: 0;
      pointer-events: none;
    }
    
    .prizelist {
      background: url(https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/home-prize-pool.png) no-repeat top center / contain;
      height: 338px;
      position: absolute;
      margin-top: 0;
      width: 100%;
      left: 47%;
      top: 0;
      transform: translateX(-50%);
      max-width: 1069px;
      z-index: 1;
      
      ul {
        position: relative;
        height: 100%;
        padding: 0;
        margin: 0;
        
        li {
          &.prizeClass0 {
            top: 8%;
            left: 34.4%;
            line-height: 1.2;
            width: 116px;
            text-align: center;
          }
          
          &.prizeClass1 {
            top: 32%;
            left: 34.4%;
            line-height: 1.2;
            width: 116px;
            text-align: center;
          }
          
          &.prizeClass2 {
            top: 55%;
            left: 34.4%;
            line-height: 1.2;
            width: 118px;
            text-align: center;
          }
          
          &.prizeClass3 {
            span.first {
              .money {
                font-size: 60px;
                text-shadow: 2px 3px 4px #f95b56;
                color: #fff1f1;
              }
              
              .numClass3 {
                // background: #ffffff;
                margin: 0 5px;
                width: 46px;
                height: 68px;
                display: block;
                padding: 0;
                line-height: 68px;
                border-radius: 6px;
              }
              
              font-size: 60px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            
            top: 27%;
            left: 56%;
            font-weight: 600;
          }
          
          &.prizeClass4 {
            &::after {
              position: absolute;
              content: "";
              width: 40px;
              left: -46px;
              bottom: 43%;
              height: 1px;
              background: #fff;
            }
            
            &::before {
              position: absolute;
              content: "";
              width: 40px;
              right: -46px;
              bottom: 43%;
              height: 1px;
              background: #fff;
            }
            
            span.first {
              font-weight: 400;
              
              .money {
                display: none;
              }
              
              color: #fbfbfb;
              font-size: 18px;
            }
            
            bottom: 28%;
            left: 63%;
          }
          
          &.refresh {
            top: 31%;
            right: 24.5%;
            cursor: pointer;
          }
          
          span.first {
            justify-content: center;
            display: flex;
            color: #ffffff;
            font-size: 22px;
            font-weight: 600;
          }
          
          position: absolute;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
}
</style>

