import { render, staticRenderFns } from "./homeComparisonchart.vue?vue&type=template&id=65ce992f&scoped=true"
import script from "./homeComparisonchart.vue?vue&type=script&lang=js"
export * from "./homeComparisonchart.vue?vue&type=script&lang=js"
import style0 from "./homeComparisonchart.vue?vue&type=style&index=0&id=65ce992f&prod&lang=less"
import style1 from "./homeComparisonchart.vue?vue&type=style&index=1&id=65ce992f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ce992f",
  null
  
)

export default component.exports