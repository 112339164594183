import { render, staticRenderFns } from "./homeslogan.vue?vue&type=template&id=472176ea&scoped=true"
import script from "./homeslogan.vue?vue&type=script&lang=js"
export * from "./homeslogan.vue?vue&type=script&lang=js"
import style0 from "./homeslogan.vue?vue&type=style&index=0&id=472176ea&prod&lang=less"
import style1 from "./homeslogan.vue?vue&type=style&index=1&id=472176ea&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472176ea",
  null
  
)

export default component.exports