<template>
  <div class="syj_bet-progress ">
    <div class="syj_bet-progress-content">
      <ul class="syj_bet-progresslist">
        <li>
          <div @click="onOpenbet">
            <img :src="progressimage11"/>
            <p>
              <span>[ <label>投注时间</label> ]</span>
              <span>{{ progresslist.start_time }} ~ {{ progresslist.end_time }}
            </span>
              <button>
                立即投注
              </button>
            </p>
          </div>
        </li>
        <li class="betstep1">
          <div :class="progresslist.ing_status == 1 ? 'actived' : 'gray'">
            <img :src="progresslist.ing_status == 1?progressimage22:progressimage2"/>
            <p>
              <span>[ <label>竞猜时间段 </label>]</span>
              <span>{{ progresslist.ing_start_time }} ~
                {{ progresslist.ing_end_time }}
              </span>
              <button v-if="progresslist.ing_status == 1" @click="hometimeorder">
                实时订单
              </button>
              <button v-else>
                实时订单
              </button>
            </p>
          </div>
        </li>
        <li class="betstep2" v-cloak>
          <div :class="progresslist.prize_status == 1 ? 'actived' : 'gray'">
            <img :src="progresslist.prize_status == 1?progressimage33:progressimage3"/>
            <p>
              <span>[ <label>中奖公示 </label> ]</span>
              <span> {{ progresslist.prize_time }} </span>
              <button v-if="progresslist.prize_status == 1" @click="homeresults">
                查看结果
              </button>
              <button v-else>
                查看结果
              </button>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";

export default {
  name: "homeBetProgress",
  data() {
    return {
      progresslist: "",
      progressimage1: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homestep01.png",
      progressimage2: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homestep02.png",
      progressimage3: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/homestep03.png",
      progressimage11: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/8aca1c1b43c5bbc941409b161d4780b3.gif",
      progressimage22: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/33757d988dba1e690665906e087dfec4.gif",
      progressimage33: "https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/80b0d1037065214ad21415db891d6ce7.gif",
      onOpenBetinfo: this.onOpenBetinfo,
      
    };
  },
  props: {
    actualorder: {
      type: [Number, String],
      default: ""
    },
    BonuspoolAmount: {
      type: [Number, String],
      default: ""//接收的数据名称为BonuspoolAmount，值类型为shuz
    },
  },
  methods: {
    onOpenbet() {
      let nowTime = new Date();
      let betting_end_time = new Date(
          this.progresslist.betting_end_time
      );
      let betting_start_time = new Date(
          this.progresslist.betting_start_time
      );
      if (
          betting_end_time.getTime() < nowTime.getTime() || nowTime.getTime() < betting_start_time.getTime()
      ) {
        this.$toast.show("投注已截止咯～", 10000);
        return;
      } else {
        this.onOpenBetinfo = true
        this.$emit("onOpenbet")
      }
      // this.homeBetProgress();
    },
    homeresults: function () {
      this.$router.push({path: "/Win-results"});
    },
    hometimeorder: function () {
      this.$router.push({path: "/time-pool"});
    },
    homeBetProgress() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "current_activity",
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data.data;
        that.progresslist = response.activity_round ? response.activity_round : '';
        // console.log('history jenny',that.progresslist)
      });
    },
  },
  
  mounted() {
    this.homeBetProgress();
    //   console.log('history jenny')
    //   this.homeHistoryWinmessage.push({
    //       message: "ghjghjghjghj",
    //     }  );
  },
};
</script>
<style lang="less">[v-cloak] {
  display: none !important;
}

.syj_bet-progress {
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  max-width: 1240px;
  position: relative;
  margin: auto;
  
  .syj_bet-progress-content {
    width: 100%;
    margin: 20px auto 20px;
    
    button {
      position: initial;
      width: 130px;
      height: 34px;
      background: #fff;
      margin: 6px auto;
      opacity: 0;
    }
    
    ul {
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-around;
      width: 100%;
      
      li {
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
        
        p {
          z-index: 1;
          
          span:first-child {
            font-size: 24px;
            color: #fff;
            margin-bottom: 0px;
            font-weight: 600;
            opacity: 0;
            
            label {
              
              color: #fe3426;
              
              &.grey {
                color: #878787;
              }
            }
          }
          
          margin: 0;
          display: flex;
          flex-direction: column;
          padding-right: 20px;
          font-weight: lighter;
          font-size: 14px;
          padding-left: 92px;
        }
        
        // &::after {
        //   z-index: -1;
        //   position: absolute;
        //   content: "";
        //   background: url("../assets/images/homestep01.png");
        //   width: 398px;
        //   height: 204px;
        //   right: 0;
        //   top: 0;
        // }
        
        // &.betstep1 {
        //   &::after {
        //     background: url("../assets/images/step02.png");
        //   }
        // }
        
        // &.betstep2 {
        //   &::after {
        //     background: url("../assets/images/step03.png");
        //   }
        // }
        
        div {
          display: flex;
          height: 100%;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          
          &.gray {
            filter: grayscale(100%);
          }
        }
        
        position: relative;
        color: #fff;
        text-align: center;
        z-index: 1;
        width: 398px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 204px;
      }
    }
  }
}
</style>

