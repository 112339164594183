<template>
  <div class="syj_guestrule syj_bg">
    <div class="syj_Betinfo-shadow"></div>
    <div class="oneprize-content syj_page-width">
      <div class="syj_border-linear"></div>
      <div class="bgwhite">
        <img class="popclosegrule cursorPointer" @click="isguessclose" src="../assets/images/homePopclose.png">
        <ul class="syj-content">
          <li>
            <div class="spanred"><span> [ </span>中奖规则 <span> ] </span></div>
            <div v-html="guestruleone"></div>
          </li>
          <li>
            <div class="spanred">
              <span> [ </span> 注意事项 <span> ] </span>
            </div>
            <div v-html="guestruletwo"></div>
          </li>
          <li>
            <div class="spanred">
              <span> [ </span> 举例说明 <span> ] </span>
            </div>
            <div v-html="guestruleimage"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";

export default {
  name: "guestrule",
  data() {
    return {
      guestruleone: " ",
      guestruletwo: " ",
      guestruleimage: " ",
    };
  },
  mounted() {
    this.guestRule();
  },
  methods: {
    isguessclose() {
      this.$emit("isGuessClose");
    },
    guestRule() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "current_activity",
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        that.guestruleone = response.data.activity.rules_content;
        that.guestruletwo = response.data.activity.attention_content;
        that.guestruleimage = response.data.activity.example_content;
        console.log("guestruleone", response.data.activity.attention_content);
      });
    },
  },
};
</script>
<style lang="less">
.syj_guestrule {
  ul {
    margin: 0;
  }
  
  .popclosegrule {
    float: right;
    padding: 0 10px;
  }
  
  &.syj_bg {
    margin: 0;
    z-index: 100;
  }
  
  .bgwhite {
    background: #fff;
    padding: 6px 0;
  }
  
  .oneprize-content {
    padding: 10px;
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    
    li {
      p {
        img {
          height: 330px !important;
          width: 500px !important;
        }
        
        margin: 5px 0;
        font-size: 14px;
      }
      
      .spanred {
        text-align: center;
        
        span {
          color: #fe3426;
        }
        
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #000;
        width: 100%;
        display: block;
      }
      
      margin-bottom: 10px;
      text-align: left;
      width: calc(100% - 60px);
      flex-wrap: wrap;
      
    }
    
    padding: 10px;
  }
}
</style>

<style scoped lang="less">
@import '~@/style/common.less';

.syj_guestrule {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  padding: 0;
  overflow-y: auto;
  
  .oneprize-content {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    margin: 6% auto;
    width: 748px;
  }
}

</style>

