<template>
  <div class="syj_page-width">
    <div class="syj_historywin " v-if="this.scrolllunbo == true" @click="goButtom">
      <div class="cursorPointer">
        <img src="../assets/images/historybg.png">
      </div>
      <div class="syj_historywin-content">
        <div class="syj_historywin-content-box">
          <ul class="history_marquee">
            <li v-for="item in homeHistoryWinmessage" :key="item.key">
              <img :src="`${item.imgs}?x-oss-process=image/resize,w_500/quality,q_80/format,webp`" height="20"/> {{ item.title }}
            </li>
          </ul>
          <ul class="history_marquee">
            <li class="li" v-for="item in homeHistoryWinmessage" :key="item.key">
              <img :src="`${item.imgs}?x-oss-process=image/resize,w_500/quality,q_80/format,webp`" height="20"/> {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="syj_historywin " v-else-if="this.scrolllunbo == false || this.scrolllunbotime == true">
      <div class="syj_historywin-content">
        <div class="cursorPointer">
          <img src="../assets/images/historybg.png">
        </div>
        <div class="syj_historywin-content-box">
          <ul class="history_marquee">
            <li class="li" v-for="item in homeHistoryWinmessage" :key="item.key">
              <img :src="`${item.imgs}?x-oss-process=image/resize,w_500/quality,q_80/format,webp`" height="20"/> {{ item.title }}
            </li>
          </ul>
          <ul class="history_marquee">
            <li class="li" v-for="item in homeHistoryWinmessage" :key="item.key">
              <img :src="`${item.imgs}?x-oss-process=image/resize,w_500/quality,q_80/format,webp`" height="20"/> {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import maiyuanRequest from "../units/maiyaunRuquest.js";

export default {
  name: "homeHistoryWin",
  data() {
    return {
      homeHistoryWinmessage: [],
    };
  },
  props: ["goButtom", "scrolllunbo", "scrolllunbotime"],
  methods: {
    getwinprize() {
      let that = this;
      maiyuanRequest({
        method: "get",
        url: "previous_awards",
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        that.homeHistoryWinmessage = response.data;
      });
    },
  },
  mounted() {
    this.getwinprize();
    //   console.log('history jenny')
    //   this.homeHistoryWinmessage.push({
    //       message: "ghjghjghjghj",
    //     }  );
  },
};
</script>
<style lang="less">
.syj_historywin {
  background: linear-gradient(to right, #c0020e, #4a030300);
  width: 100%;
  height: 60px;
  border-radius: 10px;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  .cursorPointer {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .syj_historywin-content {
    display: flex;
    justify-content: flex-start;
    height: 32px;
    overflow: hidden;
    width: 100%;
    color: #fff;
    &:hover {
      .history_marquee {
        animation-play-state: paused;
        cursor: pointer;
      }
    }
    
    
    ul {
      //width: 88%;
      
      //&:hover {
      //  animation-play-state: paused;
      //  cursor: pointer;
      //}
      
      margin: 0;
      list-style: none;
      animation: animline 10s linear infinite;
      
      li {
        font-size: 14px;
        height: 32px;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

@keyframes animline {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>

<style lang="less" scoped>
@import '~@/style/common.less';
.syj_page-width {
  
  .media(1440, {
    width: 100% !important;
    padding: 0 22px;
    box-sizing: border-box;
  });
}
</style>
