<template>
  <div class="syj_homecenterbet syj_page-width">
    <title class="commom-title"><span>集中投注区间投注数</span></title>
    <div style="height: 280px" ref="chart2"></div>
  </div>
</template>
    
    <script>
import maiyuanRequest from "../units/maiyaunRuquest.js";
import { getActivity } from "../units/activity.js";
const echarts = require("echarts"); //折线图
export default {
  name: "homecenterbet",
  data() {
    return {
      chartData: [],
      chartnum: [],
    };
  },
  mounted() {
    this.homeCenterbet();
  },

  methods: {
    initCharts2() {
      let that = this;
      let myChart = echarts.init(this.$refs.chart2);
      myChart.setOption({
        grid: {
          show: false,
          right: "0%",
          width: "90%",
          left: "5%",
        },

        title: "",
        splitNumber: 20,
        tooltip: {},
        xAxis: {
          axisLine: {
            //x轴线的颜色以及宽度
            show: true,
            lineStyle: {
              color: "#3d1d4c",
              width: 1,
              type: "solid",
            },
          },

          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            textStyle: {
              color: "#fff",
            },
          },
          splitNumber: 12,
          data: that.chartData.date_time,
        },
        yAxis: {
          nameLocation: "end",
          name: "(投注数)",
          nameTextStyle: {
            fontSize: 14,
            align: "right",
            verticalAlign: "bottom",
            fontWeight: "lighter",
            lineHeight: 20,
            color: "#fff",
          },
          margin: 10,
          type: "value",
          splitNumber: 3,
          // min: 0,
          // max: 600,
          splitLine: {
            show: false,
          },
          axisLabel: {
            //x轴文字的配置
            textStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            show: true,
            //y轴线的颜色以及宽度
            lineStyle: {
              color: "#3d1d4c",
              width: 1,
              type: "solid",
            },
          },
        },
        //图例
        legend: {},
        series: [
          //虚线
          {
            nameLocation: "end",
            type: "line",
            symbolSize: 5, //拐点圆的大小
            color: ["#e81f3c"], //折线条的颜色
            data: that.chartnum.date_time_value,
            smooth: true, //关键点，为true是不支持虚线的，实线就用true
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
            },
            label: {
              show: true,
              color: "#e81f3c",
              lineHeight: 12,
              backgroundColor: null,
              position: "top",
            },
          },
        ],
      });
    },

    homeCenterbet() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "betting_trend",
        params: { activtiy_id: activtiyId, activity_round_id: activityRoundId },
        // headers: { "Authori-zation": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0aWdnZXIubGl0dGxlb2JhLmNuIiwiYXVkIjoidGlnZ2VyLmxpdHRsZW9iYS5jbiIsImlhdCI6MTY1MzcxMTMwMiwibmJmIjoxNjUzNzExMzAyLCJleHAiOjE2NTM3MjIxMDIsImp0aSI6eyJpZCI6OTYsInR5cGUiOiJhcGkifX0.zSpNCyWVhdO4cVfFs1FBjb47kpgefI0kUWa5HWgy65w" }
      }).then((res) => {
        let response = res.data;
        that.data = response.data;
        console.log("alksdhkalsd ",res)
        if( response.status==400 ){
          return
        } 
        if(response.data.length ==0 ){
          return
        }
        let chatdate = [];
        let chatnum = [];
        that.data.date_time.forEach((item) => {
          chatdate.push(item);
        });
        that.data.date_time_value.forEach((item) => {
          chatnum.push(item);
        });
        that.chartData.date_time = chatdate;
        that.chartnum.date_time_value = chatnum;
        this.initCharts2();
        console.log("chartDatajenny奖金 ", that.chartData.date_time);
        console.log("chartDatajenny奖金zhide ", that.chartnum.date_time_value);
      });
    },
  },
};
</script>
   <style lang="less"  >
.syj_homecenterbet.syj_page-width {
  margin-bottom: 10px;
  margin-top: 30px;
  z-index: 0;
}
</style>
    
    